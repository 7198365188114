import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../Services/ActiveLink';

const classNames = require('classnames');

const Button = ({ children, color, size, as, basic, onClick, style }) => {
  const className = classNames({
    button: true,
    '--big': size === 'big',
    '--small': size === 'small',
    '--red': color === 'red',
    '--white': color === 'white',
    '--basic': basic
  });
  if (as === 'button') {
    return (
      <button type="button" onClick={onClick} className={className} style={style}>
        {children}
      </button>
    );
  }
  if (as === 'link') {
    return (
      <Link href={onClick()}>
        <a href={onClick()} className={className} style={style}>
          {children}
        </a>
      </Link>
    );
  }
  return null;
};
Button.defaultProps = {
  basic: false,
  color: null,
  size: null,
  as: 'button',
  onClick: () => {},
  children: null,
  style: null
};
Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  color: PropTypes.string,
  size: PropTypes.string,
  basic: PropTypes.bool,
  as: PropTypes.string,
  style: PropTypes.shape({}),
  onClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
};
export default Button;
