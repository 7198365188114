/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import { withRouter } from 'next/router';
import Link from 'next/link';
import React, { Children } from 'react';
import PropTypes from 'prop-types';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const ActiveLink = ({ router, children, ...props }) => {
  const child = Children.only(children);

  let className = child.props.className || '';
  if (router.pathname === props.href && props.activeClassName) {
    className = `${className} ${props.activeClassName}`.trim();
  }

  delete props.activeClassName;

  if (props.href.charAt(0) === '#') {
    return (
      <AnchorLink {...props} offset="100" href={props.href}>
        {children}
      </AnchorLink>
    );
  }

  return <Link {...props}>{React.cloneElement(child, { className })}</Link>;
};
ActiveLink.defaultProps = {
  children: null,
  activeClassName: null
};
ActiveLink.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  href: PropTypes.string.isRequired,
  activeClassName: PropTypes.string,
  router: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};

export default withRouter(ActiveLink);
