import React from 'react';
import PropTypes from 'prop-types';

const classNames = require('classnames');

const Article = ({ children, center, color }) => {
  const className = classNames({
    article: true,
    '--center': center,
    '--purple': color === 'purple',
    '--blue': color === 'blue',
    '--yellow': color === 'yellow',
    '--pink': color === 'pink',
    '--green': color === 'green',
    '--white': color === 'white'
  });

  return <article className={className}>{children}</article>;
};
Article.defaultProps = {
  center: false,
  children: null,
  color: null
};
Article.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  center: PropTypes.bool,
  color: PropTypes.string
};
export default Article;
